<template>
  <div class="page-products-index">
    <back>
      <div v-if="!$app.store.state('app.likes').includes(id*1)"><n-icon icon="heart" @click="toggleLike" /></div>
      <div v-else><n-icon icon="heart-filled" class="like" @click="toggleLike" /></div>
      <!--      <div><n-icon icon="share" /></div>-->
    </back>

    <div class="product-content">
      <n-loader :loading="$var('load') || $var('loadCart')" />
      <n-slider :data="product.images" :options="imagesOptions" pagination class="images">
        <template #item="{item}">
          <n-link :to="{name: 'products.photos', params: {id: product.id, photoId: item.id},}"></n-link>
          <img :src="item.src" alt="image">
        </template>
      </n-slider>

      <div class="container">
        <div class="title-wrap">
          <h2>{{ product.title }}</h2>
          <div class="price">
            <template v-if="product.count && product.status === 'active'">{{ $n.numberFormat(product.cost) }} ₸</template>
            <template v-else>Нет в наличии</template>
          </div>
        </div>

        <div class="reviews">
          <div>
            <star-rating read-only :star-size="15" :rating="product.storeId ? product.rating : product.author.rating" :increment="0.01" />
          </div>
          <n-link v-if="product.id" class="show-reviews" :to="{name: 'products.reviews', params: {id: product.id}}">
            Смотреть отзывы ({{ product.storeId ? product.reviewsCount : product.author.reviewsCount }})
          </n-link>
        </div>

        <div class="description">
          <div v-if="product.code" class="item">
            <div class="title">Артикул:</div>
            <div class="info">{{ product.code }}</div>
          </div>
          <div v-if="product.description" class="item">
            <div class="title">Описание:</div>
            <div class="info">{{ product.description }}</div>
          </div>
          <div v-if="my" class="item">
            <div class="title">Просмотры:</div>
            <div class="info">{{ product.viewsCount }}</div>
          </div>
          <div v-if="my" class="item">
            <div class="title">Клики "Позвонить":</div>
            <div class="info">{{ product.callCount }}</div>
          </div>
          <div v-if="my" class="item">
            <div class="title">Продажи:</div>
            <div class="info">{{ product.salesCount }}</div>
          </div>
        </div>

        <template v-if="!my">
          <template v-if="product.count">
            <n-button v-if="!product.inCart" class="main-btn blue" @click="toCart">Добавить в корзину</n-button>
            <n-link v-else :to="{name: 'cart'}"><n-button class="main-btn green">Посмотреть в корзине</n-button></n-link>
          </template>
          <n-link :to="phone" type="phone" @click="call"><n-button class="main-btn">Позвонить владельцу</n-button></n-link>
        </template>
        <n-link v-else :to="{name: 'products.edit', params: {id: product.id}}">
          <n-button class="main-btn">Редактировать товар</n-button>
        </n-link>
      </div>
    </div>

    <template v-if="product && ($n.size(storeProducts) || $var('loadStoreProducts'))">
      <div class="container">
        <h3 v-if="product.store">Товары магазина</h3>
        <h3 v-else>Другие товары этого владельца</h3>
      </div>
      <c-products-list-top :data="storeProducts" :loading="$var('loadStoreProducts')" />
    </template>

    <div v-if="product.store" class="container">
      <n-link :to="{name: 'stores.index', params: {id: product.storeId}}"><n-button class="main-btn">Перейти в магазин</n-button></n-link>
    </div>

    <div class="container">
      <h3>Похожие товары</h3>
    </div>
    <c-products-list-top :data="sameProducts" :loading="$var('loadSame')" />
  </div>
</template>

<script>
export default {
  name: 'PageProductsIndex',
  data() {
    return {
      product: {},
      sameProducts: [],
      storeProducts: [],
      imagesOptions: {
        loop: false,
        centeredSlides: true,
      },
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    },
    phone() {
      return this.product.store ? this.product?.store?.phone : this.product?.author?.phone
    },
    my() {
      return $app.auth.user().id && ($app.auth.user().id === this.product.authorId)
    },
  },
  watch: {
    id(value) {
      if (this.product.id !== value && this.$var('load') !== true) {
        this.load()
      }
    },
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.$var('load', true)
      $api.app.products.get(this.id).with('author').with('inCart').with('store').with('images').then((response) => {
        this.product = response.data.content
        this.loadSame()
        this.loadStoreProducts()
      }).finally(() => {
        this.$var('load', false)
      })
    },
    loadSame() {
      this.$var('loadSame', true)
      $api.app.products.get().sort('createdAt', 'desc').filter({ categoryId: this.product.categoryId, }).then((response) => {
        this.sameProducts = response.data.content
      }).finally(() => {
        this.$var('loadSame', false)
      })
    },
    loadStoreProducts() {
      this.$var('loadStoreProducts', true)
      const api = $api.app.products.get().sort('salesCount', 'desc').size(10)
      if (this.product.storeId) {
        api.filter({ storeId: this.product.storeId, })
      } else {
        api.filter({ authorId: this.product.authorId, })
      }
      api.then((response) => {
        this.storeProducts = response.data.content
      }).finally(() => {
        this.$var('loadStoreProducts', false)
      })
    },
    toCart() {
      this.$var('loadCart', true)
      $api.app.cart.add(this.id).then((response) => {
        this.load()
        $app.store.action('app.cartCount')
      }).finally(() => {
        this.$var('loadCart', false)
      })
    },
    toggleLike() {
      this.$var('loadLike', true)
      $api.app.products.like(this.id).then((response) => {
      }).finally(() => {
        this.$var('loadLike', false)
      })
    },
    call() {
      $api.app.products.call(this.id).then((response) => {})
    },
  },
}
</script>

<style scoped lang="scss">
.page-products-index {
  .like {
    color: #F59477;
    font-size: 20px;
  }

  .product-content {
    position: relative;

    .images {
      margin-bottom: 25px;
      min-height: 400px;

      &::v-deep .swiper-slide {
        height: 400px;
        overflow: hidden;
        position: relative;
        img { width: 100%; height: 100%; object-fit: cover; }
        a { position: absolute; top: 0; bottom: 0; right: 0; left: 0; }
      }
    }

    .title-wrap {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      margin-bottom: 6px;

      .price {
        margin-left: 15px;
        white-space: nowrap;
      }
    }

    .reviews {
      font-size: 12px;
      color: #BABABA;
      display: flex;
      align-items: center;

      .show-reviews{
        text-decoration-line: underline;
        margin-left: 14px;
      }
    }

    .description {
      margin: 10px 0 20px;
      padding: 10px 0 0;
      border-top: 1px solid #EBEBEB;

      .item {
        display: flex;
        align-items: flex-start;
        margin-bottom: 6px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      .title {
        font-size: 12px;
        color: #BABABA;
        width: 30%;
      }

      .info {
        font-size: 12px;
        color: #404040;
        width: 70%;
      }
    }
  }


  .main-btn {
    width: 100%;
    border: 1px solid #2A82D3;
    box-sizing: border-box;
    border-radius: 100px;
    font-weight: 500;
    font-size: 12px;
    height: 40px;
    background: #FFF;
    color: #2A82D3;
    transition: all .2s ease;
    margin-bottom: 10px;

    &.blue {
      background: #2A82D3;
      color: #FFF;
    }
    &.green {
      background: var(--success);
      border: 1px solid var(--success);
      color: #FFF;
    }
  }

  h3 {
    margin-top: 25px;
  }

  .other-slider {
    position: relative;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 14px;

    .swiper-slide {
      width: 43%;

      .product {
        padding: 0;
      }
    }
  }
}
</style>
